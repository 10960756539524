.glyphicon.fast-right-spinner {
  -webkit-animation: glyphicon-spin-r 1s infinite linear;
  animation: glyphicon-spin-r 1s infinite linear;
}

.glyphicon.normal-right-spinner {
  -webkit-animation: glyphicon-spin-r 2s infinite linear;
  animation: glyphicon-spin-r 2s infinite linear;
}

.glyphicon.slow-right-spinner {
  -webkit-animation: glyphicon-spin-r 3s infinite linear;
  animation: glyphicon-spin-r 3s infinite linear;
}

.glyphicon.fast-left-spinner {
  -webkit-animation: glyphicon-spin-l 1s infinite linear;
  animation: glyphicon-spin-l 1s infinite linear;
}

.glyphicon.normal-left-spinner {
  -webkit-animation: glyphicon-spin-l 2s infinite linear;
  animation: glyphicon-spin-l 2s infinite linear;
}

.glyphicon.slow-left-spinner {
  -webkit-animation: glyphicon-spin-l 3s infinite linear;
  animation: glyphicon-spin-l 3s infinite linear;
}

@-webkit-keyframes glyphicon-spin-r {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
  }

  100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
  }
}

@keyframes glyphicon-spin-r {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
  }

  100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
  }
}

@-webkit-keyframes glyphicon-spin-l {
  0% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
  }

  100% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
  }
}

@keyframes glyphicon-spin-l {
  0% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
  }

  100% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
  }
}