@font-face {
  font-family: 'TradeGothic';
  font-weight: lighter;
  src: url( '../assets/fonts/TradeGothic/Light.eot'); /* IE9 Compat Modes */
  src: url( '../assets/fonts/TradeGothic/Light.eot?#iefix' ) format( 'embedded-opentype' ), /* IE6-IE8 */
       url( '../assets/fonts/TradeGothic/Light.woff' )       format( 'woff' ), /* Pretty Modern Browsers */
       url( '../assets/fonts/TradeGothic/Light.ttf' )        format( 'truetype' ), /* Safari, Android, iOS */
       url( '../assets/fonts/TradeGothic/Light.svg#TradeGothicforNike365-Light' ) format( 'svg' ); /* Legacy iOS */
}
@font-face {
  font-family: 'TradeGothic';
  font-weight: normal;
  src: url( '../assets/fonts/TradeGothic/Normal.eot' ); /* IE9 Compat Modes */
  src: url( '../assets/fonts/TradeGothic/Normal.eot?#iefix' ) format( 'embedded-opentype' ), /* IE6-IE8 */
       url( '../assets/fonts/TradeGothic/Normal.woff' )       format( 'woff' ), /* Pretty Modern Browsers */
       url( '../assets/fonts/TradeGothic/Normal.ttf' )        format( 'truetype' ), /* Safari, Android, iOS */
       url( '../assets/fonts/TradeGothic/Normal.svg#TradeGothicforNike365-Roman' ) format( 'svg' ); /* Legacy iOS */
}
@font-face {
  font-family: 'TradeGothic';
  font-weight: bold;
  src: url( '../assets/fonts/TradeGothic/Bold.eot' ); /* IE9 Compat Modes */
  src: url( '../assets/fonts/TradeGothic/Bold.eot?#iefix' ) format( 'embedded-opentype' ), /* IE6-IE8 */
       url( '../assets/fonts/TradeGothic/Bold.woff' )       format( 'woff' ), /* Pretty Modern Browsers */
       url( '../assets/fonts/TradeGothic/Bold.ttf' )        format( 'truetype' ), /* Safari, Android, iOS */
       url( '../assets/fonts/TradeGothic/Bold.svg#TradeGothicforNike365-BdCn' ) format( 'svg' ); /* Legacy iOS */
}
@font-face {
  font-family: 'Futura';
  font-weight: bold;
  font-style: italic;
  src: url( '../assets/fonts/Futura/ExtraBoldOblique.woff' ) format( 'woff' ), /* Pretty Modern Browsers */
       url( '../assets/fonts/Futura/ExtraBoldOblique.otf' ) format( 'opentype' ), /* Safari, Android, iOS */
       url( '../assets/fonts/Futura/ExtraBoldOblique.svg#TradeGothicforNike365-BdCn' ) format( 'svg' ); /* Legacy iOS */
}


