// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** 1st90 variables **/
  --navbar-height: 46px;
--navbar-top-mobile-height: 65px;
--navbar-bottom-mobile-height: 60px;
--layout-height: 50px;

--progress-button-width: 42px;
--progress-button-corner-margin: 8px;

--ot-file-size-xs: 30px;
--ot-file-size-ss: 32px;
--ot-file-size-sm: 44px;
--ot-file-size-md: 72px;
--ot-file-size-lg: 104px;
--ot-file-size-xl: 132px;
--ot-file-padding: 0 4px;

--white-three: #fff;
--white-two: #f0f0f0;
--white-one: #c6cfda;
--greyish-brown: #575757;
--dark-blue: #2b3643;
--dark-blue-two: #0b1c2b;
--dark-blue-three: #217ebd;
--moderate-green: #54bdaf;
--red-pink: #E08283;
--blue: #3598dc;
--dark-grey: #777;
--grey: #ccc;
--grey-default: #eeeeee;
--grey-list-items: #f8f8f8;
--grey-light: #f0f0f0;
--first90-yellow: #f0cd61;
--red-questions: #d30208;
--white: white;
--black: black;

--hr-green: #C8FA0A;
--hr-aqua: #00B4AA;
--hr-blue: #00A0DC;
--hr-purple: #6E1E78;
--hr-red: #E63264;

--active-btn: #444444;
--nonactive-color: rgba(0, 0, 0, 0.22);

--grey-text: #797979;

}

