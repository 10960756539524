@import "dynamic_theme_variables_defaults";

body {
  font-family: $body-font-var;
}

h1.main {
  font-family: $header-font-var;
  border-bottom: 1px solid $header-color-var;
  color: $header-color-var;
}

.highlight-color {
  color: $highlight-color-var !important;
}

.highlight-background-color {
  background-color: $highlight-color-var !important;
}

.highlight-svg-color {
  fill: $highlight-color-var;
}

.highlight-border-color {
  border-color: $highlight-color-var !important;
}

.highlight-border-color-2 {
  border-color: $highlight-color-var !important;
  border-width: 2px;
}

.habit-container:hover, .habit-container.complete, .habit-container.highlighted {
  background-color: $highlight-color-var !important;
}

.inverted {
  .habit-container:hover, .habit-container.complete, .habit-container.highlighted {
    background-color: white !important;
    // background-color: #212121 !important; dark mode
    color: --grey-text;
  }
}
.dark-theme{
  .inverted {
    .habit-container:hover, .habit-container.complete, .habit-container.highlighted {
      background-color: #212121 !important;
      color: #adadad;
    }
  }
}
.checklist-container:hover, .checklist-container.complete {
  background-color: $highlight-color-var !important;
}

.option-container:hover, .option-container.selected {
  background-color: $highlight-color-var !important;
  color: white;
}
.poll-step-3 .option-container:hover,.poll-step-3 .option-container.selected {
  background-color:transparent !important;
  // color: white;
}
.option-container.correct {
  background-color: $highlight-faded-color-var !important;
  color: white;
}

.stats-container .rationale {
  background-color: $highlight-faded-color-var !important;
  color: white;
}

.daily-button:hover, .daily-button.started, .daily-button.active {
  color: $highlight-color-var !important;
  border-color: $highlight-color-var !important;
}

.leaderboard-container .user-container.active .form-control, .leaderboard-container .user-container.active .input-group-addon, .leaderboard-container .user-container:hover .form-control, .leaderboard-container .user-container:hover .input-group-addon {
  background-color: $highlight-color-var !important;
  border-color: $highlight-color-var !important;
}

.theme-color {
  color: $theme-color-var !important;
}

.theme-background-color {
  background-color: $theme-color-var !important;
}

.theme-border-color {
  border-color: $theme-color-var !important;
}

.badge, [class^=bounce] {
  background-color: $theme-color-var !important;
}

.stat-svg-color-1-grayed {
  fill: $stat1-shaded-color-var;
}

.stat-svg-color-2-grayed {
  fill: $stat2-shaded-color-var;
}

.stat-svg-color-3-grayed {
  fill: $stat3-shaded-color-var;
}

.stat-svg-color-4-grayed {
  fill: $stat4-shaded-color-var;
}

.stat-svg-color-5-grayed {
  fill: $stat5-shaded-color-var;
}

.stat-svg-color-1 {
  fill: $stat1-color-var;
}

.stat-svg-color-2 {
  fill: $stat2-color-var;
}

.stat-svg-color-3 {
  fill: $stat3-color-var;
}

.stat-svg-color-4 {
  fill: $stat4-color-var;
}

.stat-svg-color-5 {
  fill: $stat5-color-var;
}

.stat-color-1 {
  border-color: $stat1-color-var !important;
  background-color: $stat1-color-var !important;
}

.stat-color-2 {
  border-color: $stat2-color-var !important;
  background-color: $stat2-color-var !important;
}

.stat-color-3 {
  border-color: $stat3-color-var !important;
  background-color: $stat3-color-var !important;
}

.stat-color-4 {
  border-color: $stat4-color-var !important;
  background-color: $stat4-color-var !important;
}

.stat-color-5 {
  border-color: $stat5-color-var !important;
  background-color: $stat5-color-var !important;
}

.stat-color-1,
.stat-color-2,
.stat-color-3,
.stat-color-4,
.stat-color-5 {
  // FIXME: How can we use this? Only Javascript?
  // color: choose-contrast-color($theme-color) !important;
  color: --white !important;
}

.theme-hoverable {
  &:hover {
    background-color: $theme-color-var !important;
    color: white;
  }
}

.alert.alert-grey {
  background: #F8F8F8;
  border: 1px solid #e6e6e6;
  color: #222;
}

a.btn.btn-themed,
a.btn.btn-themed:visited,
.btn.btn-themed {
  background-color: $highlight-color-var !important;
  color: white;
  &:hover {
    background-color: $highlight-faded-color-var;
  }
}
