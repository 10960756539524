.arrow {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-top: 2px solid #000;
  border-right: 2px solid #000;
  &.arrow-sm {
    width: 15px;
    height: 15px;
  }
  &.arrow-white {
    border-top-color: white;
    border-right-color: white;
  }
}
.arrow-top {
  transform: rotate(-45deg);
}
.arrow-right {
  transform: rotate(45deg);
}
.arrow-bottom {
  transform: rotate(135deg);
}
.arrow-left {
  transform: rotate(-135deg);
}
.arrow-top-narrow {
  transform: rotate(-45deg) skew(-15deg, -15deg);
}
.arrow-top-wide {
  transform: rotate(-45deg) skew(7deg, 7deg);
}
.arrow-top-left {
  transform: rotate(-90deg) skew(-10deg, -10deg);
}
.arrow-top-right {
  transform: rotate(0) skew(-10deg, -10deg);
}
.arrow-bottom-left {
  transform: rotate(180deg) skew(-10deg, -10deg);
}
.arrow-bottom-right {
  transform: rotate(90deg) skew(-10deg, -10deg);
}

$close-size: 30px;
.close-icon{
  width: $close-size;
  height: $close-size;
  position: relative;

  &.close-icon-sm {
    width: 25px;
    height: 25px;
  }
  &.close-icon-sm:after, &.close-icon-sm:before {
    width: 25px;
    height: 2px;
  }
  &.close-icon-white:before, &.close-icon-white:after {
    background: white;
  }

  &:before{
    content: '';
    top: 0px;
    position: absolute;
    width: $close-size;
    height: 2px;
    left: 0px;
    background: black;
    transform: rotateX(40deg);
    -moz-transform: rotate(40deg);
    -o-transform: rotate(40deg);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(50deg);
    -webkit-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
  }
  &:after {
    content: '';
    top: 0px;
    left: 0px;
    width: $close-size;
    position: absolute;
    height: 2px;
    background: black;
    transform: rotateX(-40deg);
    -moz-transform: rotate(-40deg);
    -o-transform: rotate(-40deg);
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-40deg);
    -webkit-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
  }
}

.glyphicon-badge {
  //background-image: url('./assets/icons/badge-on.png');
  background-image: url('../assets/icon/badge-off.png');
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  display: inline-block;
  height: 14px;
  width: 15px;
  &.glyphicon-badge-white {
    background-image: url('../assets/icon/badge-on.png');
  }
}
button {
  &:active,
  &.active {
    .glyphicon-badge {
      background-image: url('../assets/icon/badge-on.png');
    }
  }
}
