$screen-xs-height: 600px;
$screen-xs: 768px;
$screen-sm: 992px;
$screen-md: 1200px;

$responsive-width: $screen-sm;

@mixin xs-height {
  @media ( max-width: $screen-xs - 1 ) {
    @content
  }
}

@mixin xs {
  @media ( max-width: $screen-xs - 1 ) {
    @content
  }
}

@mixin gt-xs {
  @media ( min-width: $screen-xs - 1 ) {
    @content
  }
}

@mixin sm {
  @media ( max-width: $screen-sm - 1 ) {
    @content
  }
}

@mixin gt-sm {
  @media ( min-width: $screen-sm - 1 ) {
    @content
  }
}

@mixin md {
  @media ( min-width: $screen-sm ) and ( max-width: $screen-md - 1 ) {
    @content
  }
}

@mixin gt-md {
  @media ( min-width: $screen-md - 1 ) {
    @content
  }
}

@mixin lg {
  @media ( min-width: $screen-md ) {
    @content
  }
}

@mixin responsive {
  @media ( max-width: $responsive-width ) {
    @content
  }
}
