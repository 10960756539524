$body-font: 'TradeGothic';
$header-font: 'Futura';

//1st90
$theme-color: #54BDAF;
$highlight-color: #54BDAF;
//Cohesity
// $theme-color: #67BF1B;
// $highlight-color: #67BF1B;

$highlightheader-color: #FFFFFF;
$themeheader-color: #FFFFFF;
$highlight-faded-color: rgba(84, 189, 175, .5);
$header-color: #444444;
$stat1-color: #575757;
$stat2-color: #C8FA0A;
$stat3-color: #00A0DC;
$stat4-color: #6E1E78;
$stat5-color: #E63264;
$stat1-shaded-color: #363636;
$stat2-shaded-color: #799806;
$stat3-shaded-color: #00719c;
$stat4-shaded-color: #3c1042;
$stat5-shaded-color: #771a34;

$body-font-var: var(--body-font, $body-font), sans-serif;
$header-font-var: var(--header-font, $header-font), sans-serif;

$theme-color-var: var(--theme-color, $theme-color);
$highlight-color-var: var(--highlight-color, $highlight-color);
$highlightheader-color-var: var(--highlightheader-color, $highlightheader-color);
$themeheader-color-var: var(--themeheader-color, $themeheader-color);
$highlight-faded-color-var: var(--highlight-faded-color, $highlight-faded-color);
$header-color-var: var(--header-color, $header-color);
$stat1-color-var: var(--stat1-color, $stat1-color);
$stat2-color-var: var(--stat2-color, $stat2-color);
$stat3-color-var: var(--stat3-color, $stat3-color);
$stat4-color-var: var(--stat4-color, $stat4-color);
$stat5-color-var: var(--stat5-color, $stat5-color);
$stat1-shaded-color-var: var(--stat1-shaded-color, $stat1-shaded-color);
$stat2-shaded-color-var: var(--stat2-shaded-color, $stat2-shaded-color);
$stat3-shaded-color-var: var(--stat3-shaded-color, $stat3-shaded-color);
$stat4-shaded-color-var: var(--stat4-shaded-color, $stat4-shaded-color);
$stat5-shaded-color-var: var(--stat5-shaded-color, $stat5-shaded-color);
$client-background-var: var(--client-background, $theme-color);

:root {
  --body-font: 'TradeGothic', sans-serif;
  --header-font: Roboto, sans-serif;
  
  --theme-color: #54BDAF; //1st90
  --highlight-color: #54BDAF; //1st90

  // --theme-color: #67BF1B; //Cohesity
  // --highlight-color: #67BF1B; //Cohesity
  --highlight-faded-color: rgba(84, 189, 175, .5);
  --highlightheader-color: #FFFFFF;
  --themeheader-color: #FFFFFF;
  --header-color: #444444;
  --stat1-color: #575757;
  --stat2-color: #C8FA0A;
  --stat3-color: #C8FA0A;
  --stat4-color: #6E1E78;
  --stat5-color: #E63264;
  --stat1-shaded-color: #363636;
  --stat2-shaded-color: #799806;
  --stat3-shaded-color: #00719c;
  --stat4-shaded-color: #3c1042;
  --stat5-shaded-color: #771a34;
  --client-background: #54BDAF;
}
