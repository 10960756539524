@import "dynamic_theme_variables_defaults";

$width: 100%;
$font-size-1: 16px;
$font-size-2: 12px;
$padding: 7px 7px 7px 5px;
$active-label-top: -14px;

.mtr-group {
  position: relative;
  @media (min-height: 600px) {
    margin-bottom: 30px;
  }
  margin-bottom: 22.5px;
}
.mtr-input {
  > input {
    font-size: $font-size-1;
    padding: $padding;
    display: block;
    width: $width;
    border: none;
    border-bottom: 1px solid --nonactive-color;
    -webkit-appearance: none;
    border-radius: 0;
  }
  > input:focus {
    outline: none;
  }

  > label {
    color: --nonactive-color;
    font-size: $font-size-1;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 10px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }

  /* active state */
  > input:focus ~ label,
  > input:valid ~ label {
    top: $active-label-top;
    font-size: $font-size-2;
    color: $highlight-color-var;
  }

  /* BOTTOM BARS ================================= */
  > .bar {
    position: relative;
    display: block;
    width: $width;
  }
  > .bar:before,
  > .bar:after {
    content: "";
    height: 2px;
    width: 0;
    bottom: 1px;
    position: absolute;
    background: $highlight-color-var;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }
  > .bar:before {
    left: 50%;
  }
  > .bar:after {
    right: 50%;
  }

  /* active state */
  > input:focus ~ .bar:before,
  > input:focus ~ .bar:after {
    width: 50%;
  }

  /* HIGHLIGHTER ================================== */
  > .highlight {
    position: absolute;
    height: 60%;
    width: 100px;
    top: 25%;
    left: 0;
    pointer-events: none;
    opacity: 0.5;
  }
  > .control-icon {
    position: absolute;
    top: unset;
    right: 5px;
    bottom: 8px;
    color: --nonactive-color;
  }

  /* active state */
  > input:focus ~ .highlight {
    -webkit-animation: inputHighlighter 0.3s ease;
    -moz-animation: inputHighlighter 0.3s ease;
    animation: inputHighlighter 0.3s ease;
  }

  /* ANIMATIONS ================ */
  @-webkit-keyframes inputHighlighter {
    from {
      background: $highlight-color-var;
    }
    to {
      width: 0;
      background: transparent;
    }
  }
  @-moz-keyframes inputHighlighter {
    from {
      background: $highlight-color-var;
    }
    to {
      width: 0;
      background: transparent;
    }
  }
  @keyframes inputHighlighter {
    from {
      background: $highlight-color-var;
    }
    to {
      width: 0;
      background: transparent;
    }
  }
}
.mtr-select {
  // font-family: $body-font-var;
  position: relative;
  width: $width;

  .select-text {
    position: relative;
    font-family: inherit;
    background-color: transparent;
    width: $width;
    padding: $padding;
    font-size: $font-size-1;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid --nonactive-color;
  }

  /* Remove focus */
  .select-text:focus {
    outline: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0);
  }

  /* Use custom arrow */
  .select-text {
    appearance: none;
    -webkit-appearance: none;
  }

  &:after {
    position: absolute;
    top: $font-size-1;
    right: 10px;
    /* Styling the down arrow */
    width: 0;
    height: 0;
    padding: 0;
    content: "";
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid --nonactive-color;
    pointer-events: none;
  }

  /* LABEL ======================================= */
  .select-label {
    color: --nonactive-color;
    font-size: $font-size-1;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 10px;
    transition: 0.3s ease all;
  }

  /* active state */
  .select-text:focus ~ .select-label,
  .select-text:valid ~ .select-label {
    color: $highlight-color-var;
    top: $active-label-top;
    transition: 0.3s ease all;
    font-size: $font-size-2;
  }

  /* BOTTOM BARS ================================= */
  .select-bar {
    position: relative;
    display: block;
    width: $width;
  }

  .select-bar:before,
  .select-bar:after {
    content: "";
    height: 2px;
    width: 0;
    bottom: 1px;
    position: absolute;
    background: $highlight-color-var;
    transition: 0.3s ease all;
  }

  .select-bar:before {
    left: 50%;
  }

  .select-bar:after {
    right: 50%;
  }

  /* active state */
  .select-text:focus ~ .select-bar:before, .select-text:focus ~ .select-bar:after {
    width: 50%;
  }

  /* HIGHLIGHTER ================================== */
  .select-highlight {
    position: absolute;
    height: 60%;
    width: 100px;
    top: 25%;
    left: 0;
    pointer-events: none;
    opacity: 0.5;
  }
}
.mtr-btn-unselected {
  border: none;
  border-radius: 4px;
  padding: 0 16px;
  min-width: 64px;
  min-height: 36px;
  vertical-align: middle;
  text-align: center;
  text-overflow: ellipsis;
  text-transform: uppercase;
  color: $highlight-color-var;
  background-color: rgb(255, 255, 255);
  font-family: $body-font-var;
  font-size: 14px;
  font-weight: 500;
  line-height: 35px;
  overflow: hidden;
  outline: none;
  cursor: pointer;
  border: 1px solid $highlight-color-var;

  &:focus, &:hover {
    text-decoration: none;
    color: $highlight-color-var;
  }
}
.mtr-btn, .mtr-btn-unselected {
  &.-tile {
    text-transform: none;
    line-height: 1.44;
    height: 15vh;
    .align-bottom {
      display: inline-block;
    }
  }
  &.-capitalize {
    text-transform: capitalize;
  }
}
.mtr-btn {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  border: none;
  border-radius: 4px;
  padding: 0 16px;
  min-width: 64px;
  min-height: 36px;
  vertical-align: middle;
  text-align: center;
  text-overflow: ellipsis;
  text-transform: uppercase;
  color: rgb(255, 255, 255);
  background-color: var(--theme-color);
  font-family: $body-font-var;
  font-size: 14px;
  font-weight: 500;
  line-height: 36px;
  overflow: hidden;
  outline: none;
  cursor: pointer;

  &.-shadow {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    transition: box-shadow 0.2s;
  }

  &:focus, &:hover {
    text-decoration: none;
    color: white;
  }

  &::-moz-focus-inner {
    border: none;
  }

  /* Overlay */
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgb(255, 255, 255);
    opacity: 0;
    transition: opacity 0.2s;
  }

  /* Ripple */
  &::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 50%;
    padding: 50%;
    width: 32px; /* Safari */
    height: 32px; /* Safari */
    background-color: rgb( 255, 255, 255);
    opacity: 0;
    transform: translate(-50%, -50%) scale(1);
    transition: opacity 1s, transform 0.5s;
  }

  /* Hover, Focus */
  &.-shadow:hover,
  &.-shadow:focus {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  }

  &:hover::before {
    opacity: 0.08;
  }

  &:focus::before {
    opacity: 0.24;
  }

  &:hover:focus::before {
    opacity: 0.3;
  }

  /* Active */
  &.-shadow:active {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  }

  &:active::after {
    opacity: 0.32;
    transform: translate(-50%, -50%) scale(0);
    transition: transform 0s;
  }

  /* Disabled */
  &:disabled {
    color: rgba(0, 0, 0, 0.38);
    background-color: rgba(0, 0, 0, 0.12);
    box-shadow: none;
    cursor: initial;
  }

  &:disabled::before {
    opacity: 0;
  }

  &:disabled::after {
    opacity: 0;
  }
}
.mtr-toggle {
  position: relative;
  $toggle-width: 50px; // Change Me
  $toggle-height: $toggle-width / 2.5;
  display: inline-block;
  width: $toggle-width;
  height: $toggle-height;

  &.checked .mtr-toggle-toggle {
    background: $highlight-color-var;
    left: ($toggle-width - ($toggle-height * 1.4 - $toggle-height) / 2) - $toggle-height;
    transition: .5s;
  }

  &.checked .mtr-toggle-switch {
    background: $highlight-faded-color-var;
    transition: .5s;
  }

  .mtr-toggle-switch {
    display: block;
    width: $toggle-width;
    height: $toggle-height;
    background: #939393;
    border-radius: $toggle-height / 2;
    position: absolute;
    top: 0;
    transition: .5s;
  }

  .mtr-toggle-toggle {
    height: $toggle-height * 1.4;
    width: $toggle-height * 1.4;
    border-radius: 50%;
    background: white;
    position: absolute;
    top: ($toggle-height * 1.4 - $toggle-height) / -2;
    left: ($toggle-height * 1.4 - $toggle-height) / -2;
    box-shadow: 0 $toggle-width / 50 $toggle-width / 25 rgba(black, .4);
    transition: .5s;
  }
}
