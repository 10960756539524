button {
  -webkit-appearance: none;
}
.mobile-screen {
  display: grid;
  grid-template-rows: 1fr auto;
  min-height: 100vh;
  padding: 0;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;

  > .body {
    @media (min-height: 600px) {
      padding: 0px;
    }
    padding: 0px 30px 0 20px;
    .header {
      display: grid;
      grid-template-columns: 30px auto 30px;
      grid-template-rows: auto;
      margin-bottom: 30px;
      .title {
        display: block;
        text-align: center;
        font-size: 18px;
      }
      .nav-back {
        text-align: left;
      }
      // .nav-close {
      // }
    }
  }
  > .footer {
    grid-row-start: 2;
    grid-row-end: 3;
    @media (min-height: 600px) {
      padding: 0 30px 30px 30px;
    }
    padding: 0 20px 20px 20px;
  }
}

.desktop-view{
  .mobile-screen{
    max-width: 100%;
  }
}
.dark-theme{
  color: #adadad;
}