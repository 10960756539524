@import "node_modules/ngx-toastr/toastr.css";
@import "styles/toastr";
@import "styles/fonts";
@import "theme/variables";
@import "styles/mixins";
@import "styles/functions";
@import "styles/utils";
@import "styles/glyphicon_animate";
@import "styles/material";
@import "styles/mobile_screen";
@import "styles/icons";
@import "styles/dynamic_theme";
//@import "~node_modules/ngx-bar-rating/themes/br-fontawesome-theme";
@import "node_modules/ngx-bar-rating/themes/br-default-theme";

// region Globals
body {
  color: --grey-text;
  margin: 0;
  -webkit-touch-callout: none; /* prevent callout to copy image, etc when tap to hold */
  -webkit-text-size-adjust: none; /* prevent webkit from resizing text to fit */
  -webkit-user-select: none; /* prevent copy paste, to allow, change 'none' to 'text' */
  background-color: #ebecec !important;
}

.mat-mdc-radio-label-content {
  overflow: hidden;
  width: 100%;
}

.br-default .br-unit {
  margin-right: 21px;
  font-size: 32px;
  height: 18px;
  width: 18px;
  margin: 0 11px!important;
  @media (max-width: 768px){
    font-size: 28px;
  }
}
// .br-default .br-unit:last-child(){
//   margin-right: 0!important;
// }
.br-default .br-units{
  justify-content: center!important;
  margin-left: -8px;
  &:last-child{
    margin-right: 0;
  }
}
.br-default .br-unit.br-active:hover{
  color: var(--highlight-color);
  // scale: 1.5;
  // margin-top: -7px;
}
.br-fontawesome .br-active:after {
  color: var(--theme-color);
}

.br-fontawesome .br-selected:after {
  color: var(--theme-color);
}

.br-default .br-selected:after {
  color: var(--highlight-color);
}

.custom-dialog-container .mat-mdc-dialog-container {
  display: block;
  padding: 24px;
  border-radius: 4px;
  box-sizing: border-box;
  overflow: auto;
  outline: 0;
  min-width: 200px;
  background: #f9f9f9;
  height: 100%;
  min-height: inherit;
  max-height: inherit;
}

.introjs-nextbutton-disabled {
  border: 1px solid #9a9a9a;
  border-radius: 0.4em 0.4em;
  text-shadow: none;
  background-blend-mode: darken;
  padding: 6px 8px;
  letter-spacing: 0.83px;
  font-family: Roboto;
  text-align: center;
  margin: 2%;
  color: #fff;
  border:2px solid #8f8f8f;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: default;
  background-color: #8f8f8f;
  background-image: none;
  text-decoration: none;
  font-size: 13px;
  vertical-align: middle;
}

.introjs-button-disabled {
  border: 1px solid #e4e4e4;
  border-radius: 0.4em 0.4em;
  text-shadow: none;
  background-blend-mode: darken;
 padding: 6px 8px;
  letter-spacing: 0.83px;
  font-family: Roboto;
  text-align: center;
  color: #fff;
  border:2px solid #8f8f8f;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: default;
  background-color: #8f8f8f;
  background-image: none;
  text-decoration: none;
  font-size: 13px;
  vertical-align: middle;
}

a.introjs-button-disabled {
  border: 1px solid #e4e4e4;
  border-radius: 0.4em 0.4em;
  text-shadow: none;
 padding: 6px 8px;
  letter-spacing: 0.83px;
  font-family: Roboto;
  text-align: center;
  color: #fff;
  border:2px solid #8f8f8f;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: default;
  background-color: #8f8f8f!important;
  background-image: none;
  text-decoration: none;
  font-size: 13px;
}

a.introjs-button.introjs-prevbutton.introjs-disabled {
  border: 1px solid #e4e4e4;
  border-radius: 0.4em 0.4em;
  text-shadow: none;
  padding: 6px 8px;
  letter-spacing: 0.83px;
  font-family: Roboto;
  text-align: center;
  margin: 2%;
  color: #fff;
  border:2px solid #8f8f8f;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: default;
  background-image: none;
  text-decoration: none;
  font-size: 13px;
  vertical-align: middle;
}

.introjs-button-enabled {
  box-sizing: content-box;
  position: relative;
  overflow: visible;
  color: white !important;
  padding: 6px 15px;
  border: 1px solid #d4d4d4;
  margin-top: 0px !important;
  text-decoration: none;
  font: 11px / normal sans-serif;
  white-space: nowrap;
  cursor: pointer;
  outline: 0;

  -moz-background-clip: padding;
  -o-background-clip: padding-box;
  zoom: 1;
  display: inline;
  border-radius: 0.4em 0.4em;
  border-right: none;
  text-shadow: none;
  color: #ffffff;
  background-color: var(--theme-color);
  background-blend-mode: darken;
  letter-spacing: 0.83px;
  font-family: Roboto;
  text-align: center;
  font-size: 13px;
  margin: 2%;
  vertical-align: middle;
  -webkit-box-shadow: 0 12px 17px 2px rgba(0, 0, 0, 0.14), 0 5px 22px 4px rgba(0, 0, 0, 0.12),
    0 7px 8px -4px rgba(0, 0, 0, 0.2);
  box-shadow: 0 12px 17px 2px rgba(0, 0, 0, 0.14), 0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 7px 8px -4px rgba(0, 0, 0, 0.2);
}

.mat-mdc-radio-button.mat-mdc-accent.mat-mdc-radio-checked .mat-mdc-radio-outer-circle {
  border-color: var(--highlight-color);
}

.mat-mdc-radio-button.mat-mdc-accent .mat-mdc-radio-inner-circle,
.mat-mdc-radio-button.mat-mdc-accent .mat-mdc-radio-ripple .mat-mdc-ripple-element:not(.mat-mdc-radio-persistent-ripple),
.mat-mdc-radio-button.mat-mdc-accent.mat-mdc-radio-checked .mat-mdc-radio-persistent-ripple,
.mat-mdc-radio-button.mat-mdc-accent:active .mat-mdc-radio-persistent-ripple {
  background-color: var(--highlight-color);
}

*:focus {
  outline: none !important;
}

a {
  &:hover {
    text-decoration: none;
  }
}

h1.main {
  display: block;
  font-size: 42px;
  padding-bottom: 10px;
  text-align: center;
  border-bottom: none !important;

  &.-full-width {
    display: block;
  }
  &.-main-centred {
    text-align: center;
  }
  &.-main-lefted {
    text-align: left;
  }
  @include xs() {
    font-size: 26px;
  }
}
.-main-centred {
  h1.main {
    text-align: center;
  }
}
.-main-lefted {
  h1.main {
    text-align: left;
  }
}
.main-sub {
  font-style: italic;
  text-align: center;
}

#main {
  padding: 0 !important;
}

.main-container {
  padding: 0 !important;
}

p {
  margin: 0;
}
button {
  border: 1px solid --active-btn;
  box-shadow: none;
}
.btn-v4 {
  border-radius: 20px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  vertical-align: middle;
  box-shadow: -1px 2px 4px 1px rgba(86, 86, 86, 0.12);
  display: inline-block;
  padding: 0px 30px;
  font-size: 16px;
  color: var(--highlight-color);
}

.btn-v5 {
  border-radius: 20px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  vertical-align: middle;

  display: inline-block;
  padding: 0px 60px;
  font-size: 16px;
  color: white;
  background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 100%), var(--highlight-color);
}
// endregion

// region Bootstrap modifications

.btn.btn-default.active {
  border-color: --active-btn;
  background-color: --active-btn;
  color: white;
}
.row-sm {
  display: flex;
  flex-direction: row;
  margin-right: -7.5px;
  margin-left: -7.5px;
  margin-bottom: 15px;
  .col {
    flex: 1 1 50%;

    margin-right: 7.5px;
    margin-left: 7.5px;
  }
}
.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-xs-5ths {
  width: 20%;
  float: left;
}
@media (max-width: 768px) {
.introjs-top-right-aligned{
  right:15px!important;
}
}
@media (min-width: 768px) {
  .col-sm-5ths {
    width: 20%;
    float: left;
  }
  .introjs-bottom-left-aligned{
    left: 15px!important;
  }
  .introjs-right{
    top:15px!important;
  }
}

@media (min-width: 992px) {
  .col-md-5ths {
    width: 20%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .col-lg-5ths {
    width: 20%;
    float: left;
  }
}
// endregion

// region Shared
// Shared between components, but not extracted as a component

// DailyContentComponent
.btn-next {
  min-width: 100px;
}

// Tabs control
.top-tabs {
  margin: 15px 0;
  text-align: center;
  .btn {
    min-width: 100px;
  }
}

// Missing modal dialog vertical center
.modal-dialog-centered {
  display: flex;
  pointer-events: none;
  &.in {
    display: flex !important;
    pointer-events: initial;
    align-items: center;
    justify-content: center;
  }
}

.modal-isolator {
  position: relative;
  z-index: 1049;
}

// LUMBA-1418
@media (min-width: 768px) {
  .modal-dialog-desktop-right {
    margin: 0;
    left: 50%;
    top: 100px;
    bottom: 30px;
    right: 0;
    .modal-dialog {
      margin: 0 auto;
      max-height: 100%;
      border-radius: 6px;
      overflow: auto;
    }
    .modal-content {
      display: flex;
      flex-direction: column;
      border: none;
    }
    .modal-body {
      display: flex;
      flex-direction: column;
    }
  }
  .modal-dialog-desktop-right.in {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.introjs-overlay {
  position: absolute;
  box-sizing: content-box;
  z-index: 999999;
  background: -moz-radial-gradient(center, ellipse farthest-corner, rgba(0, 0, 0, 0.4) 0, rgba(0, 0, 0, 0.9) 100%);
  background: -webkit-gradient(
    radial,
    center center,
    0,
    center center,
    100%,
    color-stop(0, rgba(0, 0, 0, 0.4)),
    color-stop(100%, rgba(0, 0, 0, 0.9))
  );
  background: -webkit-radial-gradient(center, ellipse farthest-corner, rgba(0, 0, 0, 0.4) 0, rgba(0, 0, 0, 0.9) 100%);
  background: -o-radial-gradient(center, ellipse farthest-corner, rgba(0, 0, 0, 0.4) 0, rgba(0, 0, 0, 0.9) 100%);
  background: -ms-radial-gradient(center, ellipse farthest-corner, rgba(0, 0, 0, 0.4) 0, rgba(0, 0, 0, 0.9) 100%);
  background: radial-gradient(center, ellipse farthest-corner, rgba(0, 0, 0, 0.4) 0, rgba(0, 0, 0, 0.9) 100%);
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

@media (max-width: 767px) {
  .modal-dialog-desktop-right.in {
    align-items: flex-start;
  }
}
@media only screen and (min-width: 375px) and (max-width: 413px) and (orientation: portrait) {
  #survey-screen-welcome canvas {
    height: 140px !important;
    width: 320px !important;
  }
}

// Form errors
.errors {
  display: none;
  color: --red-pink;
  line-height: 1.5;
  padding: 3px 5px;
}
.has-errors {
  .errors {
    display: block;
  }
  .input-group-addon {
    border-bottom-color: --red-pink;
    border-top-color: --red-pink;
    border-left-color: --red-pink;
  }
  .form-control {
    border-bottom-color: --red-pink;
    border-top-color: --red-pink;
    border-right-color: --red-pink;
  }
}

// Quill
.ql-align-right {
  text-align: right;
}
.ql-align-left {
  text-align: left;
}
.ql-align-center {
  text-align: center;
}
.ql-align-justify {
  text-align: justify;
}
.ql-video {
  width: 100%;
}
//Links from quill
.has-quill-links {
  a {
    color: white;
    text-decoration: underline;
  }
}
.inverted .has-quill-links {
  a {
    color: var(--highlight-color);
  }
}
// endregion

.survey-screens {
  .cloud-calendar-button .add-to-calendar-widget {
    font-family: Roboto;
    background-color: #000000;
    margin: auto;
    padding: 0 18px;
    overflow: visible;
    // display: block;
    overflow: hidden;
    border-radius:20px;
    &:before,
    &:after {
      pointer-events: none;
    }
    @media only screen and (max-width: 768px) {
      padding: 0 15px;
      background-color:transparent;
    }
    a {
      color: black !important;
      text-align: left;
    }
  }
  .add-to-calendar-label {
    background-color: transparent;
    box-shadow: none !important;
    font-weight: 400;
    margin-bottom: 0;
    padding: 0;
    background-image: none;
    color: var(--themeheader-color);
    text-transform: lowercase;
  }
  .add-to-calendar-label::first-letter {
    text-transform: uppercase;
  }
  
  .add-to-calendar-dropdown {
    box-shadow: none !important;
    width: 100%;
    text-transform: capitalize;
    margin-bottom: 15px !important;
    @media (max-width: 768px) {
      padding: 8px;
      width: 185px;
    }
    a {
      border: 1px solid var(--highlight-color);
      border-radius: 6px;
      display: block;
      margin-bottom: 20px;
      padding: 6px 8px;
      &:before {
        position: relative;
        top: 2px;
      }
    }
  }
}
.dark-theme {
  .instep-onboarding-screens {
    .cloud-calendar-button .add-to-calendar-widget {
      a{
        color: #fff!important;
      }
    }
}
}
.dark-theme.sc-ion-picker-ios-h{
  .picker-wrapper{
    --background: #212121;
    color: #fff;
  }
}
.dark-theme{
  .instep-onboarding-screens {
    .add-to-calendar-label{
      color: #fff;
    }
}
}
.add-to-calendar-widget.mtr-btn:hover::before{
  opacity: 0!important;
}
.example-form-field {
.mat-mdc-form-field-empty.mat-mdc-form-field-label {
  font-weight: 700;
    color: #868686!important;
}
.mat-mdc-form-field-flex{
  background: #fff!important;
}
}
.instep-onboarding-screens {
  
  .add-to-calendar-label{
    color: #2d2d2d;
  }
  .cloud-calendar-button .add-to-calendar-widget {
    font-family: Roboto;
    background-color: transparent;
    margin: auto;
    padding: 1rem 0!important;
    overflow: visible;
    border-radius: 20px;
    display: block;
    text-align: left;
    &:before,
    &:after {
      pointer-events: none;
    }

    @media only screen and (max-width: 768px) {
      padding: 0 15px;
    }

    a {
      color: black !important;
      text-align: left;
    }
  }

  .add-to-calendar-label {
    font-weight: 700!important;
    font-size: 14px;
    background-color: transparent;
    box-shadow: none !important;
    font-weight: 400;
    margin-bottom: 0;
    padding: 0;
    background-image: none;
    // color: var(--themeheader-color);
    text-transform: lowercase;
  }

  .add-to-calendar-label::first-letter {
    text-transform: uppercase;
  }

  .add-to-calendar-dropdown {
    box-shadow: none !important;
    width: 100%;
    text-transform: capitalize;
    margin-bottom: 15px !important;
    padding: 15px 0;
    @media (max-width: 768px) {
      padding: 8px;
      width: 185px;
    }

    a {
      border: 1px solid lightgrey;
      border-radius: 6px;
      display: block;
      margin-bottom: 20px;
      padding: 6px 8px;

      &:before {
        position: relative;
        top: 2px;
      }
    }
  }
  .learning-screen-header-font{
    color: var(--theme-color)!important;
  }
}
// region ****** HACKS ********
// 1. Home page hack for inverting colors
.hidden-except-main {
  display: none;
}
.-main {
  .navbar.navbar-mobile {
    display: none !important;
  }
  .navbar.navbar-mobile .navbar-right .badge {
    background: white !important;
    color: var(--highlight-color) !important;
  }
  .navbar.navbar-mobile {
    background: var(--highlight-color) !important;
    justify-content: flex-end !important;
  }
  .hidden-except-main {
    display: block;
  }
}
.inverted {
  .btn.btn-default,
  .btn.btn-default:hover,
  .btn.btn-default:focus {
    border-color: white;
    color: white;
    background: var(--highlight-color);
  }
  .label.label-default,
  .label.label-default:hover,
  .label.label-default:focus {
    color: var(--highlight-color);
    background: white;
  }
  .btn.btn-default.active {
    border-color: white;
    color: white;
    font-weight: bold;
    background: rgba(0, 0, 0, 0.1);
  }
  &.main {
    color: white;
    border-color: white;
  }
}
// 2. We remove fixed header and footer on mobile when keyboard is opened
@media (max-height: 500px) {
  .navbar.navbar-desktop,
  .navbar.navbar-mobile {
    display: none !important;
  }
  footer {
    display: none !important;
  }
}
// 3. Remove back button from first screen cross-component
// .daily-content-screen {
//   &.hide-back-btn .nav-back {
//     display: none !important;
//   }
// }

.new-mobile-home-ui .dropdown-backdrop {
  top: 92px;
  background: #000000;
  opacity: 0.5;
}

// 4. Hacks to IE // LUMBA-1325
.onIE .questions-dots .day-dot {
  margin-bottom: 30px;
}
// endregion **** HACKS END ****

.introjs-helperLayer *,
.introjs-helperLayer *:before,
.introjs-helperLayer *:after {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  -ms-box-sizing: content-box;
  -o-box-sizing: content-box;
  box-sizing: content-box;
}

.introjs-tooltipReferenceLayer {
  box-sizing: content-box;
  position: absolute;
  visibility: hidden;
  z-index: 100000000;
  left: 7px;
  background-color: transparent;
  -webkit-transition: all 0s linear;
  -moz-transition: all 0s linear;
  -ms-transition: all 0s linear;
  -o-transition: all 0s linear;
  transition: all 0s linear -1s;
}


.introjs-helperLayer {
  box-sizing: content-box;
  position: fixed;
  mix-blend-mode: soft-light;
  z-index: 999998;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9);
  border:none!important;
  border-radius: 4px;
  box-shadow:none!important;
}

.introjs-fixParent {
  z-index: auto !important;
  opacity: 1 !important;
  -webkit-transform: none !important;
  -moz-transform: none !important;
  -ms-transform: none !important;
  -o-transform: none !important;
  transform: none !important;
}
.introjs-showElement,
tr.introjs-showElement > td,
tr.introjs-showElement > th {
  z-index: 9999999 !important;
}
.introjs-disableInteraction {
  z-index: 99999999 !important;
  position: absolute;
  background-color: white;
  opacity: 0;
  filter: alpha(opacity=0);
}
.introjs-relativePosition,
tr.introjs-showElement > td,
tr.introjs-showElement > th {
  position: relative;
}
.introjs-helperLayer {
  box-sizing: content-box;
  position: absolute;
  z-index: 999999999;
  border:none!important;
  border-radius: 4px;
  box-shadow: none !important;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.introjs-tooltipReferenceLayer {
  box-sizing: content-box;
  position: absolute;
  visibility: hidden;
  z-index: 10000000;
  background-color: transparent;
  -webkit-transition: all 0s linear;
  -moz-transition: all 0s linear;
  -ms-transition: all 0s linear;
  -o-transition: all 0s linear;
  transition: all 0s linear -1s;
}
.introjs-helperLayer *,
.introjs-helperLayer *:before,
.introjs-helperLayer *:after {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  -ms-box-sizing: content-box;
  -o-box-sizing: content-box;
  box-sizing: content-box;
}
.introjs-helperNumberLayer {
  box-sizing: content-box;
  position: absolute;
  visibility: visible;
  top: -16px;
  left: -16px;
  z-index: 9999999999 !important;
  padding: 2px;
  font-family: Arial, verdana, tahoma;
  font-size: 13px;
  font-weight: bold;
  color: white;
  text-align: center;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  background: #ff3019;
  background: -webkit-linear-gradient(top, #ff3019 0, #cf0404 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ff3019), color-stop(100%, #cf0404));
  background: -moz-linear-gradient(top, #ff3019 0, #cf0404 100%);
  background: -ms-linear-gradient(top, #ff3019 0, #cf0404 100%);
  background: -o-linear-gradient(top, #ff3019 0, #cf0404 100%);
  background: linear-gradient(to bottom, #ff3019 0, #cf0404 100%);
  width: 20px;
  height: 20px;
  line-height: 20px;
  border: 3px solid white;
  border-radius: 50%;
  filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff3019', endColorstr='#cf0404', GradientType=0)";
  filter: "progid:DXImageTransform.Microsoft.Shadow(direction=135, strength=2, color=ff0000)";
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
}
.introjs-arrow {
  border: 5px solid white;
  content: "";
  position: absolute;
}
.introjs-arrow.top {
  top: -10px;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: white;
  border-left-color: transparent;
}
.introjs-arrow.top-right {
  top: -10px;
  right: 10px;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: white;
  border-left-color: transparent;
}
.introjs-arrow.top-middle {
  top: -10px;
  left: 50%;
  margin-left: -5px;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: white;
  border-left-color: transparent;
}
.introjs-arrow.right {
  right: -10px;
  top: 10px;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: white;
}
.introjs-arrow.right-bottom {
  bottom: 10px;
  right: -10px;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: white;
}
.introjs-arrow.bottom {
  bottom: -10px;
  border-top-color: white;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
}
.introjs-arrow.bottom-middle{
  bottom: -10px;
  left: 50%;
  border-top-color: white;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
}
.introjs-arrow.bottom-right{
  bottom: -10px;
  right: 10px;
  border-top-color: white;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
}
.introjs-arrow.left {
  left: -10px;
  top: 10px;
  border-top-color: transparent;
  border-right-color: white;
  border-bottom-color: transparent;
  border-left-color: transparent;
}
.introjs-arrow.left-bottom {
  left: -10px;
  bottom: 10px;
  border-top-color: transparent;
  border-right-color: white;
  border-bottom-color: transparent;
  border-left-color: transparent;
}
.introjs-tooltip {
  box-sizing: content-box;
  position: absolute;
  visibility: visible;
  padding: 10px;
  background-color: white;
  min-width: 200px;
  max-width: 300px;
  border-radius: 3px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.4);
  -webkit-transition: opacity 0.1s ease-out;
  -moz-transition: opacity 0.1s ease-out;
  -ms-transition: opacity 0.1s ease-out;
  -o-transition: opacity 0.1s ease-out;
  transition: opacity 0.1s ease-out;
}
.introjs-tooltipbuttons {
  text-align: right;
  white-space: nowrap;
  margin: 2% 0px;
}
.introjs-button {
  box-sizing: content-box;
  position: relative;
  overflow: visible;
  display: inline-block;
  padding: 6px 15px;
  border: 1px solid #d4d4d4;
  margin: 0;
  text-decoration: none;
  text-shadow: 1px 1px 0 #fff;
  font: 11px / normal sans-serif;
  color: #333;
  white-space: nowrap;
  cursor: pointer;
  outline: 0;
  background-color: #ececec;
  -webkit-background-clip: padding;
  -moz-background-clip: padding;
  -o-background-clip: padding-box;
  -webkit-border-radius: 0.2em;
  -moz-border-radius: 0.2em;
  border-radius: 0.2em;
  zoom: 1;
  *display: inline;
  margin-top: 10px;
}
.introjs-button:focus,
.introjs-button:active {
  box-shadow: none}
.introjs-button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
.introjs-skipbutton {
  box-sizing: content-box;
  margin-right: 5px;
  color: #7a7a7a;
}
.introjs-button:focus,
.introjs-button:active {
  box-shadow: none}

.introjs-prevbutton {
  -webkit-border-radius: 0.2em 0 0 0.2em;
  -moz-border-radius: 0.2em 0 0 0.2em;
  border-radius: 0.2em 0 0 0.2em;
  border-right: 0;
}
.introjs-prevbutton.introjs-fullbutton {
  border: 1px solid #d4d4d4;
  -webkit-border-radius: 0.2em;
  -moz-border-radius: 0.2em;
  border-radius: 0.2em;
}
.introjs-nextbutton {
  -webkit-border-radius: 0 0.2em 0.2em 0;
  -moz-border-radius: 0 0.2em 0.2em 0;
  border-radius: 0 0.2em 0.2em 0;
}
.introjs-nextbutton.introjs-fullbutton {
  -webkit-border-radius: 0.2em;
  -moz-border-radius: 0.2em;
  border-radius: 0.2em;
}
.introjs-disabled,
.introjs-disabled:hover,
.introjs-disabled:focus {
  color: #fff;
  border:2px solid #8f8f8f;
  box-shadow: none;
  cursor: default;
  background-color: #8f8f8f;
  background-image: none;
  text-decoration: none;
}
.introjs-hidden {
  display: none;
}
.introjs-bullets {
  text-align: center;
}
.introjs-bullets ul {
  box-sizing: content-box;
  clear: both;
  margin: 15px auto 0;
  padding: 0;
  display: inline-block;
}
.introjs-bullets ul li {
  box-sizing: content-box;
  list-style: none;
  float: left;
  margin: 0 2px;
}
.introjs-bullets ul li a {
  box-sizing: content-box;
  display: block;
  width: 6px;
  height: 6px;
  background: #ccc;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  text-decoration: none;
  cursor: pointer;
}
.introjs-bullets ul li a:hover {
  background: #999;
}
.introjs-bullets ul li a.active {
  background: #999;
}
.introjs-progress {
  box-sizing: content-box;
  overflow: hidden;  
  height: 10px;
  margin: 10px 0 5px 0;
  border-radius: 4px;
  background-color: #ecf0f1;
}
.introjs-progressbar {
  box-sizing: content-box;
  float: left;
  width: 0;
  height: 100%;
  font-size: 10px;
  line-height: 10px;
  text-align: center;
  background-color: #08c;
}
.introjsFloatingElement {
  position: absolute;
  height: 0;
  width: 0;
  left: 50%;
  top: 50%;
}
.introjs-fixedTooltip {
  position: fixed;
  margin-top: 1%;
}
.introjs-hint {
  box-sizing: content-box;
  position: absolute;
  background: transparent;
  width: 20px;
  height: 15px;
  cursor: pointer;
}
.introjs-hint:focus {
  border: 0;
  outline: 0;
}
.introjs-hidehint {
  display: none;
}
.introjs-fixedhint {
  position: fixed;
}
.introjs-hint:hover > .introjs-hint-pulse {
  border: 5px solid rgba(60, 60, 60, 0.57);
}
.introjs-hint-pulse {
  box-sizing: content-box;
  width: 10px;
  height: 10px;
  border: 5px solid rgba(60, 60, 60, 0.27);
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  background-color: rgba(136, 136, 136, 0.24);
  z-index: 10;
  position: absolute;
  -webkit-transition: all 2s ease-out;
  -moz-transition: all 2s ease-out;
  -ms-transition: all 2s ease-out;
  -o-transition: all 2s ease-out;
  transition: all 2s ease-out;
}
.introjs-hint-no-anim .introjs-hint-dot {
  -webkit-animation: none;
  -moz-animation: none;
  animation: none;
}
.introjs-hint-dot {
  box-sizing: content-box;
  border: 10px solid rgba(146, 146, 146, 0.36);
  background: transparent;
  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
  border-radius: 60px;
  height: 50px;
  width: 50px;
  -webkit-animation: introjspulse 3s ease-out;
  -moz-animation: introjspulse 3s ease-out;
  animation: introjspulse 3s ease-out;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  position: absolute;
  top: -25px;
  left: -25px;
  z-index: 1;
  opacity: 0;
}
@-webkit-keyframes introjspulse {
  0% {
    -webkit-transform: scale(0);
    opacity: 0;
  }
  25% {
    -webkit-transform: scale(0);
    opacity: 0.1;
  }
  50% {
    -webkit-transform: scale(0.1);
    opacity: 0.3;
  }
  75% {
    -webkit-transform: scale(0.5);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}
@-moz-keyframes introjspulse {
  0% {
    -moz-transform: scale(0);
    opacity: 0;
  }
  25% {
    -moz-transform: scale(0);
    opacity: 0.1;
  }
  50% {
    -moz-transform: scale(0.1);
    opacity: 0.3;
  }
  75% {
    -moz-transform: scale(0.5);
    opacity: 0.5;
  }
  100% {
    -moz-transform: scale(1);
    opacity: 0;
  }
}
@keyframes introjspulse {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  25% {
    transform: scale(0);
    opacity: 0.1;
  }
  50% {
    transform: scale(0.1);
    opacity: 0.3;
  }
  75% {
    transform: scale(0.5);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.introjs-helperNumberLayer {
  box-sizing: content-box;
  position: absolute;
  visibility: visible;
  top: -16px;
  left: 4px;
  z-index: 9999999999 !important;
  padding: 2px;
  font-family: Arial, verdana, tahoma;
  font-size: 13px;
  font-weight: bold;
  color: white;
  text-align: center;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  background: #ff3019; /* Old browsers */
  background: -webkit-linear-gradient(top, #ff3019 0%, #cf0404 100%); /* Chrome10+,Safari5.1+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #ff3019),
    color-stop(100%, #cf0404)
  ); /* Chrome,Safari4+ */
  background: -moz-linear-gradient(top, #ff3019 0%, #cf0404 100%); /* FF3.6+ */
  background: -ms-linear-gradient(top, #ff3019 0%, #cf0404 100%); /* IE10+ */
  background: -o-linear-gradient(top, #ff3019 0%, #cf0404 100%); /* Opera 11.10+ */
  background: linear-gradient(to bottom, #ff3019 0%, #cf0404 100%); /* W3C */
  width: 20px;
  height: 20px;
  line-height: 20px;
  border: 3px solid white;
  border-radius: 50%;
  filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff3019', endColorstr='#cf0404', GradientType=0)"; /* IE6-9 */
  filter: "progid:DXImageTransform.Microsoft.Shadow(direction=135, strength=2, color=ff0000)"; /* IE10 text shadows */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
}

.introjs-button {
  box-sizing: content-box;
  position: relative;
  overflow: visible;
  display: inline-block;
  padding: 6px 15px;
  border: 1px solid #d4d4d4;
  margin: 0;
  text-decoration: none;
  text-shadow: 1px 1px 0 #fff;
  font: 11px / normal sans-serif;
  color: #333;
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  background-color: #ececec;
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f4f4f4), to(#ececec));
  background-image: -moz-linear-gradient(#f4f4f4, #ececec);
  background-image: -o-linear-gradient(#f4f4f4, #ececec);
  background-image: linear-gradient(#f4f4f4, #ececec);
  -webkit-background-clip: padding;
  -moz-background-clip: padding;
  -o-background-clip: padding-box;
  /*background-clip: padding-box;*/ /* commented out due to Opera 11.10 bug */
  -webkit-border-radius: 0.2em;
  -moz-border-radius: 0.2em;
  border-radius: 0.2em;
  /* IE hacks */
  zoom: 1;
  *display: inline;
  margin-top: 10px;
}

.introjs-disabled,
.introjs-disabled:hover,
.introjs-disabled:focus {
  color: #fff;
  border:2px solid #8f8f8f;
  box-shadow: none;
  cursor: default;
  background-color: #8f8f8f;
  background-image: none;
  text-decoration: none;
}
/* overrides extra padding on button elements in Firefox */
.introjs-button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.introjs-skipbutton {
  font-size: 25px;
  height: 20px;
  float: right;
  width: 35px;
  line-height: 19px;
  -webkit-border-radius: 0.2em 0 0 0.2em;
  -moz-border-radius: 0.2em 0 0 0.2em;
  border-radius: 0.4em 0.4em;
  border-right: none;
  text-shadow: none;
  margin: 0 !important;
  color: #ffffff;
  background-color: var(--theme-color);
  background-blend-mode: darken;
  letter-spacing: 0.83px;
  font-family: Roboto;
  text-align: center;
  font-size: 13px;
  vertical-align: middle;
  box-shadow: 0 12px 17px 2px rgba(0, 0, 0, 0.14), 0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 7px 8px -4px rgba(0, 0, 0, 0.2);
}

.introjs-prevbutton {
  border: 1px solid #4f8fab;
  border-radius: 0.4em 0.4em;
  text-shadow: none;
  background-blend-mode: darken;
  padding: 6px 8px;
  letter-spacing: 0.83px;
  font-family: Roboto;
  text-align: center;
  margin: 2%;
  color: #fff;
  border:2px solid #8f8f8f;
  background-color:#8f8f8f;
  box-shadow: none;
  cursor: default;
  background-image: none;
  text-decoration: none;
  font-size: 13px;
  vertical-align: middle;
}

.introjs-prevbutton.introjs-fullbutton {
  border: 1px solid #d4d4d4;
  -webkit-border-radius: 0.2em;
  -moz-border-radius: 0.2em;
  border-radius: 0.2em;
  color: #ffffff;
  background-color: var(--theme-color);
  background-blend-mode: darken;
  font-family: Roboto;
  letter-spacing: 0.83px;
  text-align: center;
  font-size: 13px;
  vertical-align: middle;
 }

.introjs-nextbutton,
.introjs-nextbutton.introjs-fullbutton{
  border: 2px solid #c9c6c6;
  -webkit-border-radius: 0 0.2em 0.2em 0;
  -moz-border-radius: 0 0.2em 0.2em 0;
  border-radius: 0.4em 0.4em;
  text-shadow: none;
  color: #000;
  margin-top: -1px !important;
  background-color: #fff;
  background-blend-mode: darken;
  font-family: Roboto;
  text-align: center;
  letter-spacing: 0.83px;
  font-size: 13px;
  padding:6px 15px;
  vertical-align: middle;
 }

.introjs-progressbar {
  box-sizing: content-box;
  float: left;
  width: 0%;
  height: 100%;
  font-size: 10px;
  line-height: 10px;
  text-align: center;
  background-color: var(--highlight-color);
}

.introjs-button:focus,
.introjs-button:active {
  color: #000;
}

.introjs-bullets ul li a:hover {
  color: #ffffff;
  background: #999;
}
.introjs-bullets ul li a.active {
  color: #ffffff;
  background: #999;
}

.desktop-introjs-fixedTooltip {
  position: fixed !important;
  margin-top: 0% !important;
}

.desktop-step1-numberLayer {
  margin-top: 6% !important;
}
.colleagues-number-feed {
  color: #5290ac;
}

.colleagues-number-feed:hover {
  color: #000b0f;
}

.colleagues-title-feed {
  margin-top: 3px;
  font-size: 16px;
  margin-left: 38px;
}

.desktop-helper {
  margin-top: 10% !important;
}

.desktop-step2-tooltip {
  margin-top: 10% !important;
}

.desktop-introjs-highlight {
  height: 257px !important;
}

.add-to-calendar-checkbox + div.add-to-calendar-dropdown {
  display: block;
  margin-left: 20px;
}
.dark-theme{
.add-to-calendar-dropdown{
  background-color: #212121!important;
}
}
.add-to-calendar-dropdown {
  position: relative !important;
  z-index: 9!important;
  background-color: white;
  top: 0;
  left: 0;
  display: block !important;
  text-align: left;
  padding: 1em;
  margin: 0 !important;
  border-radius: 3px;
  box-shadow: 0 0 0 0.5px rgba(50, 50, 93, 0.17), 0 2px 5px 0 rgba(50, 50, 93, 0.1), 0 1px 1.5px 0 rgba(0, 0, 0, 0.07),
    0 1px 2px 0 rgba(0, 0, 0, 0.08), 0 0 0 0 transparent !important;
}

.survey-screens .add-to-calendar-dropdown a {
  border: 1px solid var(--highlight-color, #54bdaf);
  border-radius: 2px;
  display: block;
  margin-bottom: 3%;
  padding: 4px;
}

.innerTextHabit a {
  color: #99ddff;
  text-shadow: 0, 5px 0.5px white;
}

.innerTextHabit a:hover {
  color: #99ddff;
  text-shadow: 0, 5px 0.5px white;
}

.innerTextHabit a:focus {
  color: #99ddff;
  text-shadow: 0, 5px 0.5px white;
}

.habit-question a {
  color: #99ddff;
  text-shadow: 0, 5px 0.5px white;
}

.habit-question a:hover {
  color: #99ddff;
  text-shadow: 0, 5px 0.5px white;
}

.habit-question a:focus {
  color: #99ddff;
  text-shadow: 0, 5px 0.5px white;
}

.toast {
  margin-top: env(safe-area-inset-top) !important;
}

.community-step1-tooltip {
  margin-top: 60% !important;
  left: 0% !important;
  top: 0% !important;
  margin-left: 3% !important;
  display: inline-table !important;
}

.introjs-community-helperNumberLayer {
  left: 0 !important;
  top: -4px !important;
}

.community-step2-tooltip {
  position: fixed !important;
  margin-top: 0% !important;
}

.introjs-helperNumberLayer {
  left: 0 !important;
  top: -11px !important;
}

.mat-mdc-form-field-empty.mat-mdc-form-field-label {
  color: var(--theme-color);
}

.mat-mdc-form-field-underline {
  background-color: var(--theme-color);
}

mat-form-field span.mat-mdc-form-field-label-wrapper label {
  color: var(--theme-color) !important;
}

mat-form-field.mat-mdc-focused span.mat-mdc-form-field-label-wrapper label {
  color: var(--theme-color) !important;
}

.mat-mdc-focused .mat-mdc-form-field-label {
  color: var(--theme-color) !important;
}

.mat-mdc-slider-thumb {
  background-color: var(--highlight-color) !important;
}
.mat-mdc-slider-thumb-label {
  background-color: var(--highlight-color) !important;
  .mat-mdc-slider-thumb-label-text{
    color: var(--highlightheader-color)!important;
  }
}
.mat-mdc-slider-track-fill {
  background-color: var(--highlight-color) !important;
}

.mat-mdc-tab-label {
  width: 101px;
  height: 21px;
  font-family: Roboto;
  font-size: 14px;
  color: #ffffff;
  text-decoration: none solid rgb(255, 255, 255);
  line-height: 21.6px;
  text-align: center;
}

.mat-mdc-tab-group.mat-mdc-primary .mat-mdc-ink-bar,
.mat-mdc-tab-nav-bar.mat-mdc-primary .mat-mdc-ink-bar {
  background: #ffffff;
}

.introjs-fixedTooltip-new {
  position: absolute !important;
  margin-top: 0% !important;
}

.introjs-tooltip-label {
  position: absolute !important;
}

.mat-mdc-slide-toggle.mat-mdc-checked:not(.mat-mdc-disabled) .mat-mdc-slide-toggle-bar {
  background-color: #808080;
}

.mat-mdc-slide-toggle.mat-mdc-checked:not(.mat-mdc-disabled) .mat-mdc-slide-toggle-thumb {
  background-color: #fafafa;
}

.mat-mdc-pseudo-checkbox-checked::after {
  top: 2.4px !important;
  left: 1px !important;
  width: 10px !important;
  height: 5px !important;
  border-left: 2px solid currentColor !important;
  transform: rotate(-45deg) !important;
  opacity: 1 !important;
}

.introjs-tooltiptext {
  padding-top: 30px;
  font-size: 12px;
}
.progress-steps p {
  margin-bottom: 5px !important;
}
.mat-mdc-form-field-appearance-fill .mat-mdc-form-field-flex {
  background-color: #fff !important;
  width: 100%;
  height: 40px;
  padding: 7px !important;
  border: 1px solid #8c8c8c;
  border-radius: 4px !important;
  background-size: cover;
  font-family: Roboto;
  font-size: 12px;
  color: #333333;
  -webkit-text-decoration: #333333;
  text-decoration: #333333;
}
.mat-mdc-form-field-appearance-fill .mat-mdc-form-field-underline::before {
  display: none !important;
}
.rectangle-buttons span p {
  margin-bottom: 0;
}
.sc-ion-popover-ios-h {
  --width: auto;
}
//.user-profile {

//}
.full-survey-screen {
  // width: 100vw;
  // height: 100vh;
  background-color: var(--theme-color);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
.background-div{
  background-color: #fff;
}
.closeout-survey.survey-wrapper.mobile-screen {
  .sub-title-design {
    color: #000 !important;
  }
}
.introjs-helperNumberLayer {
  left: 12px !important;
}

ion-nav,
ion-tab,
ion-tabs,
ion-list {
  contain: none;
}
.introjs-skipbutton {
  width: 60px;
  box-shadow: none;
  background: none;
  color: var(--theme-color);
  font-weight: 500;
}
.introjs-overlay {
  background: #00000059;
}
.introjs-hidden{
  display: none !important;
  }

  demo-margin{
    left: -10px !important;}
    .sso-text.bg-white{
      color: #7A7A7A!important;
    }
    .sso-button.bg-white,
    .denone-sso-button.bg-white{
      border: 1px solid #8C8C8C!important;
      img{
        width: 10%;
      }
    }
    @keyframes fadeInLeft {
      0% {
        opacity: 0;
        transform: translateX(-10px);
     }
     100% {
        opacity: 1;
        transform: translateX(0);
     }
   }  
  
   #toast-container > div {
    opacity: 1;
  }
  .toast {
    font-size: initial !important;
    border: initial !important;
    backdrop-filter: blur(0) !important;
  }
  .toast-success {
    background-color: #51A351 !important;
    height: 30px;
    display: flex;
    align-items: center;
  }
  .toast-top-right{
    top:30px!important;
  }
  .toast-error {
    background-color: #BD362F !important;
    height: 30px;
    display: flex;
    align-items: center;
  }
  .toast-info {
    background-color: #2F96B4 !important;
  }
  .toast-warning {
    background-color: #F89406 !important;
    height: 30px;
    display: flex;
    align-items: center;
  }
  /* styles.css */
// @media screen and (orientation: landscape) {
  .landscape-container {
    display: block;
  }

  // .portrait-warning {
  //   display: none;
  // }
// }

// @media screen and (orientation: portrait) {
  // .landscape-container {
  //   display: none;
  // }

  .portrait-warning {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #484848;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    flex-direction: column;
    font-size: 22px;
    font-family: 'Roboto'
  }
// }
